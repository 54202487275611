// The improved version of the My Intrivity Application
import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   

import { 
    getScore, getIcon, getColor, feedback_mapping 
} from './utility.js'
import './likert_report.js';
import './characteristics_app.js';
import './strengths_app.js';
import './recommendations_app.js';
import './attitudes_app.js';
import './resultsglance_app.js';

export class IntrivityDomainDetailsApp extends LitElement {

    static get styles() {
        return css`
        `;
    }
    
    static get properties() {
        return {
            domain: { type: Object },
            domain_prefix: { type: String },
            group_report: { type: Object },
            report: { type: Object },
            printable: { type: Boolean }           // printable view
        };
    }
    
    constructor() {
        super(); 
    }
    
    render() {
        
        let row_start = 2;
        return html`
            
            <div style="margin-left: 30px; margin-right: 30px; display: grid; grid-template-columns: 1fr 5fr;">
                <div><img height="150px;" src="./images/${this.domain.image}"></div>
                <div style="font-size: 1.2em; margin-top: 30px;">${this.domain.description}</div>
            </div>
        
            <div style="border: 7px solid ${this.domain.color}; margin-right: 30px; margin-left: 30px; margin-bottom: 30px; display: grid; grid-template-columns: 1fr 1fr 2fr 4fr;">
                    <div style="margin-top: 20px; text-align: center; color: ${this.domain.color}; font-size: 4em; font-weight: bolder;">
                        ${getScore(this.report[this.domain_prefix].average)}
                    </div>
                    <div style="margin-top: 20px; font-size: larger; width: 100%; aspect-ratio: 1.0;">
                        ${getIcon(getScore(this.report[this.domain_prefix].average), this.report[this.domain_prefix].average, this.domain.color, 75)}
                    </div>
                    <div style="margin-top: 20px; font-size: larger; font-weight: bolder;">
                        At work you are<br /><span style="font-size: 1.5em; color: ${this.domain.color};">${this.domain.label[getScore(this.report[this.domain_prefix].average) - 1]}</span>
                    </div>
                    <div style="margin-top: 20px;">${this.domain.feedback[feedback_mapping[getScore(this.report[this.domain_prefix].average) - 1]]}</div>  
            </div>

            <div style="zoom: 85%; break-after: page;">
                <intrivity-results-at-glance .printable=${this.printable} .domain=${this.domain} .domain_prefix=${this.domain_prefix} .report=${this.report}></intrivity-results-at-glance>
            </div>
            
            <div style="zoom: 85%; break-before: page;">
                <intrivity-characteristics .printable=${this.printable} .group_report=${this.group_report} .domain=${this.domain} .domain_prefix=${this.domain_prefix} .report=${this.report}></intrivity-characteristics>
            </div>
        
            <div style="zoom: 85%; break-before: page;">
                <intrivity-attitudes .printable=${this.printable} .group_report=${this.group_report} .domain=${this.domain} .domain_prefix=${this.domain_prefix} .report=${this.report}></intrivity-attitudes>
            </div>
            <div style="zoom: 85%; break-before: page;">
                <intrivity-strengths .printable=${this.printable} .domain=${this.domain} .domain_prefix=${this.domain_prefix} .report=${this.report}></intrivity-strengths>
            </div>
            <div style="zoom: 85%; break-before: page;">
                <intrivity-recommendations .printable=${this.printable} .domain=${this.domain} .domain_prefix=${this.domain_prefix} .report=${this.report}></intrivity-recommendations>
            </div>
        
        `
    } 
 
}
if (!customElements.get('intrivity-domain-details')) customElements.define('intrivity-domain-details', IntrivityDomainDetailsApp);
