import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
import { doc, setDoc, getDoc, getDocs, collection, query, where, addDoc, getFirestore, collectionGroup } from "firebase/firestore";

import { emitEvent, db } from './mrts.js';
import { INTRIVITY_LOAD_CLIENTS } from './nav.js';


// Manage Clients
export class IntrivityAdminClients extends LitElement {

    static get styles() {
        return css`

        `;
    }
    
    static get properties() {
        return {
            clients: { type: Array },
            expanded: { type: Boolean }   
        };
    }

    constructor() {
        super();
        emitEvent( INTRIVITY_LOAD_CLIENTS, {}, this );
        console.log("emitted load client event");
        this.expanded = false;
        this.createInvitations = this.createInvitations.bind(this);
        this.parseCSV = this.parseCSV.bind(this);
        this.generateRandomCode = this.generateRandomCode.bind(this);
    }

    connectedCallback() {
        super.connectedCallback(); 
    }
    
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    
    render() {
        return html`
            
            <div style="margin-bottom: 5px; width: 100%; color: black; font-size: 1.5em; padding: 10px; padding-top: 15px; padding-left: 30px; background-color: gray;">
                CLIENTS  
                ${this.expanded ? html`
                    <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,10 20,10 10,0" /></svg>
                `: html`
                    <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,0 20,0 10,10" /></svg> 
                `}
            </div>            
            
            ${this.expanded ? html`
 
                ${this.clients.map((client) => html`
                    ${client.address}
                    ${client.department}
                    ${client.email}
                    ${client.institution}
                    ${client.name}
                    ${client.primary_user}
                `)}
        
                <div>
                    <label for="csvFile">Select a CSV File:</label>
                    <input type="file" id="csvFile" accept=".csv"> 
                    <button @click=${this.createInvitations}>Process</button>
                </div>
            `: html`

            `}
            
        `
    } 
    
    generateRandomCode() {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            let result = '';
            for (let i = 0; i < 6; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
    }
        
    createInvitations() {
        
        const fileInput = this.shadowRoot.getElementById('csvFile');
        const file = fileInput.files[0];
        if (!file) {
            console.error("No file selected.");
            return;
        }
        const reader = new FileReader();
        reader.onload = this.parseCSV;
        reader.readAsText(file);
         
    }
 
    async parseCSV(event) {
            const content = event.target.result;
            const rows = content.split('\n');          
            for (let i = 1; i < rows.length; i++) { // Skipping header row
                const row = rows[i].trim();
                if (row) {
                    const [firstName, lastName, email] = row.split(',');
                    const invitationQuery = query(collectionGroup(db, 'invitations'), where("email", "==", email.toLowerCase()));
                    const invitationSnapshot = await getDocs(invitationQuery);
                    if (invitationSnapshot.size > 0) {
                        console.log("Email " + email.toLowerCase() + " already has an invitation.");
                    } else {
                        console.log("Create invitation for " + email.toLowerCase());
                        const messageRef = doc(db, "groups", "111a-df23-e411-c4b3", "invitations", email.toLowerCase());
                        let data = {};
                        data.uuid = email.toLowerCase();
                        data.email = email.toLowerCase();
                        data.first = firstName;
                        data.last = lastName;
                        data.group_uuid = '111a-df23-e411-c4b3';
                        data.code = this.generateRandomCode();
                        data.status = 'unsent';
                        await setDoc(messageRef, data);
                    }
                }
            }
    }
}
if (!customElements.get('intrivity-admin-clients')) customElements.define('intrivity-admin-clients', IntrivityAdminClients);
