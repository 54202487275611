// The improved version of the My Intrivity Application
import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   

import './behaviors_app.js';

import { 
    getScore, getIcon, getColor, feedback_mapping 
} from './utility.js';

export class IntrivityRecommendationsApp extends LitElement {

    static get properties() {
        return {
            domain: { type: Object },
            domain_prefix: { type: String },
            report: { type: Object },
            expanded: { type: Boolean },
            printable: { type: Boolean }
        };
    }

    constructor() {
        super();
        this.expanded = false;
    }

    connectedCallback() {
        super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }
    
    render() {
        return html`
            <div style="margin-bottom: 5px; width: 100%; color: black; font-size: 1.5em; padding: 10px; padding-top: 15px; padding-left: 30px; background-color: gray;">
                PERSONALIZED RECOMMENDATIONS 
                ${this.printable ? html``: html`
                    ${this.expanded ? html`
                        <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,10 20,10 10,0" /></svg>
                    `: html`
                        <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,0 20,0 10,10" /></svg> 
                    `}
                `}
            </div>
        
            ${this.expanded || this.printable ? html`
                <div style="width: 100%;">

                   <div style="margin: 40px; margin-bottom: 15px; font-size: 1.5em;">BEHAVIORS</div>
        
                    ${this.domain.level_two.map((characteristic, i) => html`
                        ${characteristic.behaviors.map((behavior, j) => html`
                            <div style="margin: 20px; margin-left: 40px;"><span style="color: ${this.domain.color}; font-weight: bolder;">${behavior.name.toUpperCase()} | </span> 
                                ${this.domain.level_two[i].behaviors[j].recommendations[feedback_mapping[getScore(this.report[this.domain_prefix].level_two[i].behaviors[j].average) - 1]]} 
                            </div>
                        `)}
                    `)}
        
                    <div style="margin: 40px; margin-bottom: 15px; font-size: 1.5em;">ATTITUDES</div>
        
                    ${this.report[this.domain_prefix].attitudes.length > 0 ? html`
                    ${this.domain.attitudes.map((attitude, i) => html`
                        <div style="margin: 20px; margin-left: 40px;"><span style="color: ${this.domain.color}; font-weight: bolder;">${attitude.name.toUpperCase()} | </span> 
                            ${this.domain.attitudes[i].recommendations[feedback_mapping[getScore(this.report[this.domain_prefix].attitudes[i].average) - 1]]} 
                        </div>                      
                    `)}
                    `: html`<div style="padding-left: 40px;">No data for user.</div>`}
        
                </div>    
            `: html``}
        `
    } 
 
}
if (!customElements.get('intrivity-recommendations')) customElements.define('intrivity-recommendations', IntrivityRecommendationsApp);
