import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   

import { 
    getScore, getIcon, getColor, feedback_mapping 
} from './utility.js'

import './badge.js';
import './header_app.js';
import './profile_app.js';
import './overview.js';
import './domain-details-app.js';

export class IntrivityPrintableApp extends LitElement {

    static get properties() {
        return {
            user_profile: { type: Object },
            space_domain: { type: Object },
            message_domain: { type: Object },
            relationship_domain: { type: Object },
            time_domain: { type: Object },            
            report: { type: Object },
            group_report: { type: Object },
            group_reports: { type: Array }
        };
    }

    static get styles() {
      return css`
        .fbanner {
            width: 100%;
            border-bottom: 7px solid rgb(250, 166, 53);
            display: grid;
            grid-template-columns: 3fr 1fr ;
        }
        .fbanner_badge {
            display: grid;
            grid-template-columns: 25px 1fr ;
            margin-right: auto;
            cursor: pointer;
        }
        footer { 
            display: none;
            background-color: #333; 
            color: white; 
            text-align: center; 
            padding: 10px; 
        } 
        @media print { 
  
            /* Define the header and footer */ 
            @page { 
                margin: 0 !important;
            } 
  
            footer:after { 
                display: block; 
                text-align: center; 
            } 
            footer {
                display: block;
                width: 100%;
                position: fixed;
                bottom: 0;
            }
        } 
      `;
    } 
    
    constructor() {
        super();
        this.expanded = false;
    }

    connectedCallback() {
        super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }
    
    render() {
        return html`

         
                <div style="zoom: 75%; margin-bottom: 4px;">
                    <intrivity-header printable="true" .user_profile=${this.user_profile}></intrivity-header>
                </div>
                <div style="margin: auto; zoom: 75%; max-width: 900px;">
                    ${this.report && this.group_reports ? html`
                        <intrivity-name-banner printable="true" .group_report=${this.group_report} .group_reports=${this.group_reports} .user_profile=${this.user_profile} size="0.9" m="${getScore(this.report.m.average)}" r="${getScore(this.report.r.average)}" t="${getScore(this.report.t.average)}" s="${getScore(this.report.s.average)}"></intrivity-name-banner>
                    `: html`
                        <intrivity-name-banner printable="true" .user_profile=${this.user_profile} size="0.9"></intrivity-name-banner>
                    `}      
                </div>
                <div style="margin: auto; zoom: 75%; max-width: 900px;">
                    <intrivity-overview style="max-width: 800px;" printable="true"></intrivity-overview>
                </div>
                <div style="margin: auto; zoom: 85%; break-before: page; max-width: 900px;">
                    <intrivity-profile printable="true" .group_report=${this.group_report} .report=${this.report} .message_domain=${this.message_domain} .space_domain=${this.space_domain} .relationship_domain=${this.relationship_domain} .time_domain=${this.time_domain}></intrivity-profile>
                </div>
                <div style="margin: auto; zoom: 85%; break-before: page; max-width: 900px;">
                    <intrivity-domain-details printable="true" .group_report=${this.group_report} domain_prefix="m" .domain=${this.message_domain} .report=${this.report}></intrivity-domain-details>
                </div>
                <div style="margin: auto; zoom: 85%; break-before: page; max-width: 900px;">
                    <intrivity-domain-details printable="true" .group_report=${this.group_report} domain_prefix="s" .domain=${this.space_domain} .report=${this.report}></intrivity-domain-details>                    
                </div>
                <div style="margin: auto; zoom: 85%; break-before: page; max-width: 900px;">
                    <intrivity-domain-details printable="true" .group_report=${this.group_report} domain_prefix="t" .domain=${this.time_domain} .report=${this.report}></intrivity-domain-details>  
                </div>
                <div style="margin: auto; zoom: 85%; break-before: page; max-width: 900px;">
                    <intrivity-domain-details printable="true" .group_report=${this.group_report} domain_prefix="r" .domain=${this.relationship_domain} .report=${this.report}></intrivity-domain-details>  
                </div>
                <footer>
                    <div style="display: grid; grid-template-columns: 1fr 1fr ;">
                    <div class="fbanner_badge">
                ${ this.report && this.report.m && this.report.r && this.report.t && this.report.s ? html`
                        
                            <intrivity-badge-plain .m=${getScore(this.report.m.average)} .r=${getScore(this.report.r.average)} .s=${getScore(this.report.s.average)} .t=${getScore(this.report.t.average)} size="0.5"></intrivity-badge-plain>
                            <div style="margin-left: 8px; margin-top: -2px; font-size: small; text-align: left;">
                                ${this.user_profile.displayName}<br />
                                MRTS PROFILE ${getScore(this.report.m.average)} ${getScore(this.report.r.average)} ${getScore(this.report.t.average)} ${getScore(this.report.s.average)}
                            <div>
                        
                `: html``}        
                    </div>
                    <div style="text-align: right; padding-left: 60px; padding-right: 20px;">
                        <img style="height: 25px;" src="./images/intrivity.png" />
                    </div> 
                    </div>
                </footer> 
        `
    } 
 
}
if (!customElements.get('intrivity-printable')) customElements.define('intrivity-printable', IntrivityPrintableApp);
