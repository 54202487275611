// The improved version of the My Intrivity Application
import { html, css, LitElement, svg } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import './badge.js';
import './domain_app.js';

import { 
    emitEvent, getScore, INTRIVITY_NAV_SELECT 
} from './utility.js'

export const showNodeText = function(event) {
        //alert(domian.label[level]);
        console.log(event);
};
    
export class IntrivityProfileApp extends LitElement {

    static get styles() {
        return css`
            .teams { 
                max-width: 600px; margin: auto;
            }
            .group_list {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 10px;
            }
            .group_badge {
                display: grid;
                grid-template-columns: 60px 1fr;
            }
            .group_profile {
                display: grid;
                grid-template-columns: 30px 10px 1fr;
            }        
        `;
    }
    
    static get properties() {
        return {
            selected: { type: String },
            space_domain: { type: Object },
            message_domain: { type: Object },
            relationship_domain: { type: Object },
            time_domain: { type: Object },   
            group_report: { type: Object },
            report: { type: Object },
            printable: { type: Boolean },           // printable view
            size: { type: Number }
        };
    }

    constructor() {
        super(); 
        this.size = 1;
        this.getDomain = this.getDomain.bind(this);
        this.getSVGReport = this.getSVGReport.bind(this);
    }
    
    getDomain(domain) {
        
        this.selected = domain;      
        
    }
    
    getSVGReport(group_report) {
        let rep = { "m": {}, "r": {}, "s": {}, "t": {} };
        group_report.users.map((user) => {
            let m = getScore(user.profile.m);
            let r = getScore(user.profile.r);
            let s = getScore(user.profile.s);
            let t = getScore(user.profile.t);
            if (rep.m.hasOwnProperty(m)) {
                rep.m[m] = rep.m[m] + 1;
            } else {
                rep.m[m] = 1;
            }
            if (rep.r.hasOwnProperty(r)) {
                rep.r[r] = rep.r[r] + 1;
            } else {
                rep.r[r] = 1;
            }
            if (rep.s.hasOwnProperty(s)) {
                rep.s[s] = rep.s[s] + 1;
            } else {
                rep.s[s] = 1;
            }
            if (rep.t.hasOwnProperty(m)) {
                rep.t[t] = rep.t[t] + 1;
            } else {
                rep.t[t] = 1;
            }
        });
        return rep;
    }
    
    showNodeText(event) {
        //alert(domian.label[level]);
        console.log(event);
    }
    

    showDomainText(data, message) {
        const errorMsg = message || 'This field is required.';
        const errorElem = document.createElement('div');
        errorElem.style.backgroundColor = '#ff0000';
        errorElem.innerHTML = errorMsg;
        errorElem.style.top = `${(data && data.position && data.position.posy) ? data.position.posy : '0'}px`;
        errorElem.style.left = `${(data && data.position && data.position.posx) ? data.position.posx : '0'}px`;
        errorElem.style.position = 'absolute';
        errorElem.zIndex = 900;

        document.body.appendChild(errorElem);
    }
    
    render() {
        return html`
        
            <div style="margin-top: 50px;  width: 100%;">

                <div style="max-width: 800px; margin: auto; display: grid; grid-gap: 0px; grid-template-columns: 1fr 4fr 8fr 4fr 1fr;">

                    <div></div>
                    <div style="grid-column: 2 / span 4;">
                        <img style="cursor: pointer;" @click=${(e)=>{ if (this.selected === "message") { this.selected = undefined; } else {  this.getDomain("message"); } }} height="75px;" src="./images/${this.message_domain.image}">
                    </div>

                    ${this.selected ? html`
            
                        <div style="display: flex; align-items: flex-end;">
                            <img style="cursor: pointer;" @click=${(e)=>{ if (this.selected === "relationship") { this.selected = undefined; } else {  this.getDomain("relationship"); } }} height="75px; margin-right: 10px;" src="./images/${this.relationship_domain.image}">
                        </div>
        
                        ${this.selected === "message" ? html`
                            <intrivity-domain style="grid-column: 2 / span 3;" raw="${this.report.m.average}" .domain=${this.message_domain}></intrivity-domain>
                        `: html``}
        
                        ${this.selected === "relationship" ? html`
                            <intrivity-domain style="grid-column: 2 / span 3;" raw="${this.report.r.average}" .domain=${this.relationship_domain}></intrivity-domain>
                        `: html``}
        
                        ${this.selected === "space" ? html`
                            <intrivity-domain style="grid-column: 2 / span 3;" raw="${this.report.s.average}" .domain=${this.space_domain}></intrivity-domain>
                        `: html``}
        
                        ${this.selected === "time" ? html`
                            <intrivity-domain style="grid-column: 2 / span 3;" raw="${this.report.t.average}" .domain=${this.time_domain}></intrivity-domain>
                        `: html``}
        
        
                        <div>
                            <img style="cursor: pointer;" @click=${(e)=>{ if (this.selected === "space") { this.selected = undefined; } else {  this.getDomain("space"); } }} height="70px;" src="./images/${this.space_domain.image}">
                        </div>



                    `: html`
                        <div></div>
                        <div @click=${(e)=>{ if (this.selected === "message") { this.selected = undefined; } else {  this.getDomain("message"); } }} style="cursor: pointer; border: 10px solid ${this.message_domain.color}; display: flex; align-items: flex-end; aspect-ratio: 1; height: 150px; color: ${this.message_domain.color};">
                            <div style="font-size: 22px; margin: 10px; width: 100%; text-align: center;">
                                <div style="margin-bottom: 20%; font-size: 34px; font-weight: bolder;">${getScore(this.report.m.average)}</div>
                                <div style="font-size: 22px; width: 100%; text-align: right;">Message Domain</div>
                            </div>
                        </div>
                        <div style="display: flex; align-items: flex-end; color: white; background-color: ${this.message_domain.color};">
                            <div style="margin: 10px; width: 50%;">The indirectness and implicitness of a message.</div>
                        </div>
                        <div @click=${(e)=>{ this.getDomain("relationship"); }} style="cursor: pointer; border: 10px solid ${this.relationship_domain.color}; display: flex; align-items: flex-end; aspect-ratio: 1; height: 150px; color: ${this.relationship_domain.color};">
                            <div style="font-size: 22px; margin: 10px; width: 100%; text-align: center;">
                                <div style="margin-bottom: 20%; font-size: 34px; width: 100%; font-weight: bolder; text-align: center;">${getScore(this.report.r.average)}</div>
                                <div style="font-size: 22px; width: 100%; text-align: left;">Relationship Domain</div>
                            </div>                        
                        </div>
                        <div><img style="cursor: pointer;" @click=${(e)=>{ if (this.selected === "relationship") { this.selected = undefined; } else {  this.getDomain("relationship"); } }} height="70px;" src="./images/${this.relationship_domain.image}"></div>

                        <div></div>
                        <div style="display: flex; align-items: flex-end; text-align: right; color: white; background-color: ${this.space_domain.color};">
                            <div style="margin: 10px;">The communication cues in the physical, auditory, and vocal space.</div>
                        </div>
                        <div style="text-align: center; font-size: 22px; margin: auto; aspect-ratio: 1;">
                            ${this.group_report ? html`
                            <svg-profile .rep=${this.getSVGReport(this.group_report)} .report=${this.report} .message_domain=${this.message_domain} .time_domain=${this.time_domain} .relationship_domain=${this.relationship_domain} .space_domain=${this.space_domain}></svg-profile>    
                            `: html``}
                        </div>
                        <div style="color: white; background-color: ${this.relationship_domain.color};">
                            <div style="margin: 10px;">The role of status and the nature of relationship in communication.</div>
                        </div>
                        <div></div>

                        <div style="display: flex; align-items: flex-end;">
                            <img style="cursor: pointer;" @click=${(e)=>{ this.getDomain("space"); }} height="75px; margin-right: 10px;" src="./images/${this.space_domain.image}">
                        </div>
                        <div @click=${(e)=>{ this.getDomain("space"); }} style="cursor: pointer; border: 10px solid ${this.space_domain.color}; aspect-ratio: 1; height: 150px; color: ${this.space_domain.color};">
                            <div style="font-size: 22px; margin: 10px; text-align: center;">
                                <div style="font-size: 22px; text-align: right; width: 100%;">Space Domain</div>
                                <div style="margin-top: 20%; font-size: 34px; font-weight: bolder;">${getScore(this.report.s.average)}</div>
                            </div>               
                        </div>
                        <div style="width: 100%; text-align: right; color: white; background-color: ${this.time_domain.color};">
                            <div style="float: right; margin: 10px; width: 60%;">The communication cues in the way one moves through time.</div>
                        </div>
                        <div @click=${(e)=>{ this.getDomain("time"); }} style="cursor: pointer; border: 10px solid ${this.time_domain.color}; aspect-ratio: 1; height: 150px; color: ${this.time_domain.color};">
                            <div style="font-size: 22px; margin: 10px; text-align: center;">
                                <div style="font-size: 22px; text-align: left; width: 100%;">Time Domain</div>
                                <div style="margin-top: 20%; font-size: 34px; font-weight: bolder;">${getScore(this.report.t.average)}</div>
                            </div>
                        </div>
                        <div></div>
                    `}
        

                    <div style="grid-column: 1 / span 4; text-align: right;">
                        <img style="cursor: pointer;" @click=${(e)=>{ if (this.selected === "time") { this.selected = undefined; } else {  this.getDomain("time"); } }} height="65px;" src="./images/${this.time_domain.image}">
                    </div>
                    <div></div>

                </div>       
        
                ${this.selected ? html`
                    <div style="padding: 20px;"></div>
                    <div style="width: 100%; text-align: center;">
                        <div @click=${(e)=> {
                            emitEvent(INTRIVITY_NAV_SELECT, { "selection": this.selected }, this);
                        }} style=" cursor: pointer; padding: 5px; padding-left: 15px; padding-right: 15px; font-weight: bolder; display: inline; background-color: rgb(87, 130, 133); color: white;">GO TO DETAILS</div>
                        <div @click=${(e)=>{ this.selected = undefined; this.requestUpdate();}} style="cursor: pointer; padding: 5px; padding-left: 15px; padding-right: 15px; font-weight: bolder; display: inline; background-color: rgb(87, 130, 133); color: white;">BACK TO SUMMARY</div>     
                    </div>
                `: html``}
        
                <div class="teams">
                ${this.group_report ? html`

                    <h3>${this.group_report.title}</h3>

                    <div class="group_list">

                        ${this.group_report.users.sort((a, b) => {
                            if (a.lastName + ', ' + a.firstName < b.lastName + ', ' + b.firstName) {
                              return -1;
                            }
                            if (a.lastName + ', ' + a.firstName > b.lastName + ', ' + b.firstName) {
                              return 1;
                            }
                            return 0;
}                       ).map((user=> html`

                            ${this.selected === "message" ? html`
                                <div class="group_profile">
                                       <intrivity-domain profile="true" raw="${getScore(user.profile.m)}" .domain=${this.message_domain}></intrivity-domain>
                                       <div style="color: ${this.message_domain.color}">${getScore(user.profile.m)}</div>
                                       <div style="margin-left: 10px;">${user.lastName}, ${user.firstName}</div>   
                               </div>                            
                            `: html``}

                            ${this.selected === "relationship" ? html`
                                <div class="group_profile">
                                       <intrivity-domain profile="true" raw="${getScore(user.profile.r)}" .domain=${this.relationship_domain}></intrivity-domain>
                                       <div style="color: ${this.relationship_domain.color}">${getScore(user.profile.r)}</div>
                                       <div style="margin-left: 10px;">${user.lastName}, ${user.firstName}</div>   
                               </div>       
                            `: html``}

                            ${this.selected === "space" ? html`
                                <div class="group_profile">
                                       <intrivity-domain profile="true" raw="${getScore(user.profile.s)}" .domain=${this.space_domain}></intrivity-domain>
                                       <div style="color: ${this.space_domain.color}">${getScore(user.profile.s)}</div>
                                       <div style="margin-left: 10px;">${user.lastName}, ${user.firstName}</div>   
                               </div>                            
                            `: html``}

                            ${this.selected === "time" ? html`
                                <div class="group_profile">
                                       <intrivity-domain profile="true" raw="${getScore(user.profile.t)}" .domain=${this.time_domain}></intrivity-domain>
                                       <div style="color: ${this.time_domain.color}">${getScore(user.profile.t)}</div>
                                       <div style="margin-left: 10px;">${user.lastName}, ${user.firstName}</div>   
                               </div>                            
                            `: html``}
        
                            ${this.selected ? html``: html`

                                <div class="group_badge">
                                   <intrivity-badge-plain .m=${getScore(user.profile.m)} .r=${getScore(user.profile.r)} .s=${getScore(user.profile.s)} .t=${getScore(user.profile.t)} .size=${this.size}></intrivity-badge-plain>
                                   <div style="margin-left: 5px;">
                                       <div>${user.lastName}, ${user.firstName}</div>
                                       <div style="font-size: smaller; text-align: center; display: inline-grid; grid-template-columns: 1fr 1fr 1fr 1fr;">
                                           <div>M</div><div>R</div><div>T</div><div>S</div>
                                           <div>${getScore(user.profile.m)}</div>
                                           <div>${getScore(user.profile.r)}</div>
                                           <div>${getScore(user.profile.t)}</div>
                                           <div>${getScore(user.profile.s)}</div>
                                       </div>
                                   <div>
                               </div>
        
                            `}
        
                        ` ))}       

                    </div>
        
                `: html``}    
                </div>
        

            </div>
        
        `
    } 
 
}
if (!customElements.get('intrivity-profile')) customElements.define('intrivity-profile', IntrivityProfileApp);

export class SvgProfile extends LitElement {
    

    static get styles() {
        return css`
            .teams { 
                max-width: 600px; margin: auto;
            }
            .group_list {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 10px;
            }
            .group_badge {
                display: grid;
                grid-template-columns: 60px 1fr;
            }
            .group_profile {
                display: grid;
                grid-template-columns: 30px 10px 1fr;
            }  
            #clickable-div {
                font-size: smaller;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                position: absolute;
                visibility: hidden; /* Initially hidden */
                font-size: 18px;
            }
        `;
    }
    
    static get properties() {
        return {
            rep: { type: Object },
            selected: { type: String },
            space_domain: { type: Object },
            message_domain: { type: Object },
            relationship_domain: { type: Object },
            time_domain: { type: Object },   
            report: { type: Object }
        };
    }

    constructor() {
        super(); 
    }
    
    connectedCallback() {
        super.connectedCallback();
        console.log(this.rep);
        console.log(this.report);
        console.log("M1: " + this.rep.m["3"]);
    }
    
    svgDomainPoint(domain, textvalue, label, color, x, y ) {
        if (this.rep[domain].hasOwnProperty(textvalue)) {
            return svg`<a @click="${(e)=> { this.showTextMessage(e, label);}}"><circle cx="${x}" cy="${y}" r="${(3 + this.rep[domain][textvalue])}" fill="${color}" /></a></svg>`;
        } 
    }
    
    showTextMessage(event, message) {
        const div = this.shadowRoot.getElementById('clickable-div');
        div.innerText = message;
        div.style.left = event.pageX + 'px';
        div.style.top = event.pageY + 'px';
        div.style.visibility = 'visible'; // Make the div visible
    }
  
    svgDomainMyPoint(domain) {
        if (getScore(this.report[domain].average) === 1) {
            if (domain === "m") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.message_domain.label[0]);}}"><circle cx="90" cy="90" r="2" fill="black" /></a>`;
            } else if (domain === "s") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.space_domain.label[0]);}}"><circle cx="90" cy="110" r="2" fill="black" /></a>`;
            } else if (domain === "t") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.time_domain.label[0]);}}"><circle cx="110" cy="110" r="2" fill="black" /></a>`;
            } else if (domain === "r") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.relationship_domain.label[0]);}}"><circle cx="110" cy="90" r="2" fill="black" /></a>`;
            } 
        } else if (getScore(this.report[domain].average) === 2) {
            if (domain === "m") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.message_domain.label[1]);}}"><circle cx="70" cy="70" r="2" fill="black" /></a>`;
            } else if (domain === "s") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.space_domain.label[1]);}}"><circle cx="70" cy="130" r="2" fill="black" /></a>`;
            } else if (domain === "t") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.time_domain.label[1]);}}"><circle cx="130" cy="130" r="2" fill="black" /></a>`;
            } else if (domain === "r") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.relationship_domain.label[1]);}}"><circle cx="130" cy="70" r="2" fill="black" /></a>`;
            }            
        } else if (getScore(this.report[domain].average) === 3) {
            if (domain === "m") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.message_domain.label[2]);}}"><circle cx="50" cy="50" r="2" fill="black" /></a>`;
            } else if (domain === "s") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.space_domain.label[2]);}}"><circle cx="50" cy="150" r="2" fill="black" /></a>`;
            } else if (domain === "t") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.time_domain.label[2]);}}"><circle cx="150" cy="150" r="2" fill="black" /></a>`;
            } else if (domain === "r") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.relationship_domain.label[2]);}}"><circle cx="150" cy="50" r="2" fill="black" /></a>`;
            }
        } else if (getScore(this.report[domain].average) === 4) {
            if (domain === "m") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.message_domain.label[3]);}}"><circle cx="30" cy="30" r="2" fill="black" /></a>`;
            } else if (domain === "s") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.space_domain.label[3]);}}"><circle cx="30" cy="170" r="2" fill="black" /></a>`;
            } else if (domain === "t") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.time_domain.label[3]);}}"><circle cx="170" cy="170" r="2" fill="black" /></a>`;
            } else if (domain === "r") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.relationship_domain.label[3]);}}"><circle cx="170" cy="30" r="2" fill="black" /></a>`;
            }
        } else if (getScore(this.report[domain].average) === 5) {
            if (domain === "m") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.message_domain.label[4]);}}"><circle cx="10" cy="10" r="2" fill="black" /></a>`;
            } else if (domain === "s") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.space_domain.label[4]);}}"><circle cx="10" cy="190" r="2" fill="black" /></a>`;
            } else if (domain === "t") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.time_domain.label[4]);}}"><circle cx="190" cy="190" r="2" fill="black" /></a>`;
            } else if (domain === "r") {
                return svg`<a @click="${(e)=> { this.showTextMessage(e, this.relationship_domain.label[4]);}}"><circle cx="190" cy="10" r="2" fill="black" /></a>`;
            }
        }
        
    }
    
    render() {
        return html`
            <div id="clickable-div" @click=${(e)=>{ this.shadowRoot.getElementById('clickable-div').style.visibility = "hidden"; }}>Hello, World!</div>
            <svg width="100%" height="100%" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <rect width="200" height="200" stroke="white" stroke-width="1" fill="white" />
                <rect x="90" y="90" width="20" height="20" stroke="lightgray" stroke-width="1" fill="transparent" />
                <rect x="70" y="70" width="60" height="60" stroke="lightgray" stroke-width="1" fill="transparent" />
                <rect x="50" y="50" width="100" height="100" stroke="lightgray" stroke-width="1" fill="transparent" />
                <rect x="30" y="30" width="140" height="140" stroke="lightgray" stroke-width="1" fill="transparent" />
                <rect x="10" y="10" width="180" height="180" stroke="lightgray" stroke-width="1" fill="transparent" />
                <line x1="100" y1="0" x2="100" y2="200"  style="stroke:gray;stroke-width:2" />
                <line x1="0" y1="100" x2="200" y2="100"  style="stroke:gray;stroke-width:2" />
                ${this.svgDomainPoint("m", "1", this.message_domain.label[0], this.message_domain.color, 90, 90)}
                ${this.svgDomainPoint("m", "2", this.message_domain.label[1], this.message_domain.color, 70, 70)}
                ${this.svgDomainPoint("m", "3", this.message_domain.label[2], this.message_domain.color, 50, 50)}
                ${this.svgDomainPoint("m", "4", this.message_domain.label[3], this.message_domain.color, 30, 30)}
                ${this.svgDomainPoint("m", "5", this.message_domain.label[4], this.message_domain.color, 10, 10)}
                ${this.svgDomainPoint("s", "1", this.space_domain.label[0], this.space_domain.color, 90, 110)}
                ${this.svgDomainPoint("s", "2", this.space_domain.label[1], this.space_domain.color, 70, 130)}
                ${this.svgDomainPoint("s", "3", this.space_domain.label[2], this.space_domain.color, 50, 150)}
                ${this.svgDomainPoint("s", "4", this.space_domain.label[3], this.space_domain.color, 30, 170)}
                ${this.svgDomainPoint("s", "5", this.space_domain.label[4], this.space_domain.color, 10, 190)}
                ${this.svgDomainPoint("t", "1", this.time_domain.label[0], this.time_domain.color, 110, 110)}
                ${this.svgDomainPoint("t", "2", this.time_domain.label[1], this.time_domain.color, 130, 130)}
                ${this.svgDomainPoint("t", "3", this.time_domain.label[2], this.time_domain.color, 150, 150)}
                ${this.svgDomainPoint("t", "4", this.time_domain.label[3], this.time_domain.color, 170, 170)}
                ${this.svgDomainPoint("t", "5", this.time_domain.label[4], this.time_domain.color, 190, 190)}
                ${this.svgDomainPoint("r", "1", this.relationship_domain.label[0], this.relationship_domain.color, 110, 90)}
                ${this.svgDomainPoint("r", "2", this.relationship_domain.label[1], this.relationship_domain.color, 130, 70)}
                ${this.svgDomainPoint("r", "3", this.relationship_domain.label[2], this.relationship_domain.color, 150, 50)}
                ${this.svgDomainPoint("r", "4", this.relationship_domain.label[3], this.relationship_domain.color, 170, 30)}
                ${this.svgDomainPoint("r", "5", this.relationship_domain.label[4], this.relationship_domain.color, 190, 10)}
                ${this.svgDomainMyPoint("m")}
                ${this.svgDomainMyPoint("s")}
                ${this.svgDomainMyPoint("t")}
                ${this.svgDomainMyPoint("r")}               
            </svg>      `
    } 

}
if (!customElements.get('svg-profile')) customElements.define('svg-profile', SvgProfile);
