import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   

export class IntrivityBadge extends LitElement {

    static get properties() {
        return {
            m: { type: Number },
            r: { type: Number },
            t: { type: Number },
            s: { type: Number },
            size: { type: Number },
            type: { type: String },
            last: { type: String },
            first: { type: String },
            selected: { type: String }
        };
    }

    constructor() {
        super();
        this.size = 1;
        this.type = "line";
    }

    connectedCallback() {
        super.connectedCallback();
        //document.addEventListener('intrivity::badge', this.downloadBadge);
    }

    disconnectedCallback() {
        //document.removeEventListener('intrivity::badge', this.downloadBadge);
        super.disconnectedCallback();
    }

    render() {
        return html`
            ${this.selected ? html`
 
            `: html`
                ${this.type === "square" ? html`
                    <intrivity-badge-square .m=${this.m} .r=${this.r} .s=${this.s} .t=${this.t} .size=${this.size}></intrivity-badge-square>
                `: html`
                    ${this.last ? html`
                        <intrivity-badge-plain .m=${this.m} .r=${this.r} .s=${this.s} .t=${this.t} .size=${this.size}></intrivity-badge-plain>
                    `: html`
                        <intrivity-badge-line .m=${this.m} .r=${this.r} .s=${this.s} .t=${this.t} .size=${this.size}></intrivity-badge-line>
                    `}
                `}
        
            `}
        `
    } 
 
}
if (!customElements.get('intrivity-badge')) customElements.define('intrivity-badge', IntrivityBadge);
export class IntrivityBadgeSelected extends LitElement {
    static get styles() {
        return css`
            .aspect { aspect-ratio: 1; }
            .m_back { background-color: rgb(250, 166, 53); }
            .m_text_color { color: rgb(250, 166, 53); } 
            .r_back { background-color: rgb(82, 162, 170); }
            .r_text_color { color: rgb(82, 162, 170); } 
            .t_back { background-color: rgb(217, 90, 123); }
            .t_text_color { color: rgb(217, 90, 123); } 
            .s_back { background-color: rgb(175, 208, 117); }
            .s_text_color { color: rgb(175, 208, 117); }
        `;
    }
    static get properties() {
        return {
            m: { type: Number },
            r: { type: Number },
            t: { type: Number },
            s: { type: Number },
            size: { type: Number }
        };
    }
    constructor() { super(); }
    render() {
        return html`
            <div style="background-color: white; width: ${this.size*100}px; aspect-ratio: 1;">
                <intrivity-badge-marker size="${this.size*1.25}" value="${this.m}" color="rgb(250, 166, 53)"></intrivity-badge-marker>
            </div>
        `
    }    
}
if (!customElements.get('intrivity-badge-selected')) customElements.define('intrivity-badge-selected', IntrivityBadgeSelected);

export class IntrivityBadgeSquare extends LitElement {
    static get styles() {
        return css`
            .aspect { aspect-ratio: 1; }
            .m_back { background-color: rgb(250, 166, 53); }
            .m_text_color { color: rgb(250, 166, 53); } 
            .r_back { background-color: rgb(82, 162, 170); }
            .r_text_color { color: rgb(82, 162, 170); } 
            .t_back { background-color: rgb(217, 90, 123); }
            .t_text_color { color: rgb(217, 90, 123); } 
            .s_back { background-color: rgb(175, 208, 117); }
            .s_text_color { color: rgb(175, 208, 117); }
        `;
    }
    static get properties() {
        return {
            m: { type: Number },
            r: { type: Number },
            t: { type: Number },
            s: { type: Number },
            size: { type: Number }
        };
    }
    constructor() { super(); }
    render() {
        return html`
            <div style="background-color: white; width: ${this.size*100}px; aspect-ratio: 1;">
                <div style="padding: 5px; border: 1px solid black; display: grid; grid-template-columns: 1fr 1fr 1fr; text-align: center; margin-right: -10px;">
                    <intrivity-badge-marker size="${this.size*1.25}" value="${this.m}" color="rgb(250, 166, 53)"></intrivity-badge-marker>
                    <div class="aspect"></div>
                    <intrivity-badge-marker size="${this.size*1.25}" value="${this.r}" color="rgb(82, 162, 170)"></intrivity-badge-marker>
                    
                    <div style="grid-column-start: 1; grid-column-end: 4; font-size: ${this.size}em;">
                        <div style="font-size: smaller;">
                            <span style="font-size: smaller;">
                                MRTS PROFILE
                            </span>
                        </div>
                        <div>
                            <span class="m_text_color">${this.m == -1 ? "#": this.m}</span>
                            <span class="r_text_color">${this.r == -2 ? "#": this.r}</span>
                            <span class="t_text_color">${this.t == -3 ? "#": this.t}</span>  
                            <span class="s_text_color">${this.s == -4 ? "#": this.s}</span>                            
                        </div>
                    </div>
                    <intrivity-badge-marker style="margin-top: 5px;" size="${this.size*1.25}" value="${this.s}" color="rgb(175, 208, 117)"></intrivity-badge-marker>
                    
                    <div class="aspect"></div>
                        
                    <intrivity-badge-marker style="margin-top: 5px;" size="${this.size*1.25}" value="${this.t}" color="rgb(217, 90, 123)"></intrivity-badge-marker>
                
                </div>
            </div>
        `
    }    
}
if (!customElements.get('intrivity-badge-square')) customElements.define('intrivity-badge-square', IntrivityBadgeSquare);
export class IntrivityBadgeLine extends LitElement {
    static get styles() {
        return css`
            .aspect { aspect-ratio: 1; }
            .m_back { background-color: rgb(250, 166, 53); }
            .m_text_color { color: rgb(250, 166, 53); } 
            .r_back { background-color: rgb(82, 162, 170); }
            .r_text_color { color: rgb(82, 162, 170); } 
            .t_back { background-color: rgb(217, 90, 123); }
            .t_text_color { color: rgb(217, 90, 123); } 
            .s_back { background-color: rgb(175, 208, 117); }
            .s_text_color { color: rgb(175, 208, 117); }
        `;
    }
    static get properties() {
        return {
            m: { type: Number },
            r: { type: Number },
            t: { type: Number },
            s: { type: Number },
            size: { type: Number }
        };
    }
    constructor() { super(); }
    render() {
        return html`
            <div style="display: grid; grid-gap: 5px; grid-template-columns: 1fr 1fr; text-align: center; font-size: ${this.size}em;">
                <div style="display: grid; grid-gap: 2px; grid-template-columns: 1fr 1fr; text-align: center;">
                    <div class="m_back aspect"></div>
                    <div class="s_back aspect"></div>
                    <div class="r_back aspect"></div>
                    <div class="t_back aspect"></div>
                </div>
                <div style="font-size: larger; display: grid; grid-gap: 1px; grid-template-columns: 1fr 1fr 1fr 1fr; text-align: center;">
                    <div>M</div>
                    <div>R</div>
                    <div>T</div>
                    <div>S</div>
                    <div class="m_text_color">${this.m}</div> 
                    <div class="r_text_color">${this.s}</div>  
                    <div class="t_text_color">${this.r}</div>  
                    <div class="s_text_color">${this.t}</div>
                </div>
        
            </div>
        `
    }    
}
if (!customElements.get('intrivity-badge-line')) customElements.define('intrivity-badge-line', IntrivityBadgeLine);
export class IntrivityBadgePlain extends LitElement {
    static get styles() {
        return css`
            .aspect { aspect-ratio: 1; }
            .m_back { background-color: rgb(250, 166, 53); }
            .m_text_color { color: rgb(250, 166, 53); } 
            .r_back { background-color: rgb(82, 162, 170); }
            .r_text_color { color: rgb(82, 162, 170); } 
            .t_back { background-color: rgb(217, 90, 123); }
            .t_text_color { color: rgb(217, 90, 123); } 
            .s_back { background-color: rgb(175, 208, 117); }
            .s_text_color { color: rgb(175, 208, 117); } 
        `;
    }
    static get properties() {
        return {
            m: { type: Number },
            r: { type: Number },
            t: { type: Number },
            s: { type: Number },
            size: { type: Number }
        };
    }
    constructor() { super(); }
    render() {
        return html`
            <div style="width: ${this.size*50}px; aspect-ratio: 1;">
                <div style="display: grid; grid-column-gap: 4px; grid-template-columns: 1fr 1fr; text-align: center; font-size: ${this.size}em;">
                    <intrivity-badge-marker size="${this.size}" value="${this.m}" color="rgb(250, 166, 53)"></intrivity-badge-marker>
                    <intrivity-badge-marker size="${this.size}" value="${this.r}" color="rgb(82, 162, 170)"></intrivity-badge-marker>
                    <intrivity-badge-marker size="${this.size}" value="${this.s}" color="rgb(175, 208, 117)"></intrivity-badge-marker>
                    <intrivity-badge-marker size="${this.size}" value="${this.t}" color="rgb(217, 90, 123)"></intrivity-badge-marker>
                </div>
            </div>
        `
    }    
}
if (!customElements.get('intrivity-badge-plain')) customElements.define('intrivity-badge-plain', IntrivityBadgePlain);
export class IntrivityBadgeMarker extends LitElement {
    static get properties() {
        return {
            value: { type: Number },
            color: { type: String },
            size: { type: Number }
        };
    }
    constructor() { super(); }
    render() {
        return html`
            ${this.value == -1 ? html`
                <svg style="aspect-ratio: 1; width: ${this.size*25}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${this.color}" fill="transparent" stroke-width="3" />
                    <text x="25%" y="28%" transform="scale(2, 2)" dominant-baseline="middle" fill=${this.color} text-anchor="middle">M</text>    
                </svg>
            `: html``}   
            ${this.value == -2 ? html`
                <svg style="aspect-ratio: 1; width: ${this.size*25}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${this.color}" fill="transparent" stroke-width="3" />
                    <text x="25%" y="28%" transform="scale(2, 2)" dominant-baseline="middle" fill=${this.color} text-anchor="middle">R</text>    
                </svg>
            `: html``}         
            ${this.value == -3 ? html`
                <svg style="aspect-ratio: 1; width: ${this.size*25}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${this.color}" fill="transparent" stroke-width="3" />
                    <text x="25%" y="28%" transform="scale(2, 2)" dominant-baseline="middle" fill=${this.color} text-anchor="middle">T</text>    
                </svg>
            `: html``} 
            ${this.value == -4 ? html`
                <svg style="aspect-ratio: 1; width: ${this.size*25}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${this.color}" fill="transparent" stroke-width="3" />
                    <text x="25%" y="28%" transform="scale(2, 2)" dominant-baseline="middle" fill=${this.color} text-anchor="middle">S</text>    
                </svg>
            `: html``}         
            ${this.value == 0 ? html`
                <svg style="aspect-ratio: 1; width: ${this.size*25}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${this.color}" fill="transparent" stroke-width="3" />
                    <text x="25%" y="28%" transform="scale(2, 2)" dominant-baseline="middle" fill=${this.color} text-anchor="middle">X</text>    
                </svg>
            `: html``}
            ${this.value == 1 ? html`
                <svg style="aspect-ratio: 1; width: ${this.size*25}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${this.color}" fill="transparent" stroke-width="3" />
                </svg>
            `: html``}
            ${this.value == 2 ? html`
                <svg style="aspect-ratio: 1; width: ${this.size*25}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                  <rect width="100%" height="100%" stroke="${this.color}" fill="transparent" stroke-width="1" />
                  <polygon points="${this.size*25/2},${this.size*25} ${this.size*25},${this.size*25/2} ${this.size*25},${this.size*25}" fill="${this.color}"/>
                </svg>
            `: html``}
            ${this.value == 3 ? html`             
                <svg style="aspect-ratio: 1; width: ${this.size*25}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                  <rect width="100%" height="100%" stroke="${this.color}" fill="transparent" stroke-width="1" />
                  <polygon points="${this.size*25},0 ${this.size*25},${this.size*25} 0,${this.size*25}" fill="${this.color}"/>
                </svg>
            `: html``}
            ${this.value == 4 ? html`
                <svg style="aspect-ratio: 1; width: ${this.size*25}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${this.color}" fill="transparent" stroke-width="1" />
                    <polygon points="0,${this.size*25/2} 0,${this.size*25} ${this.size*25},${this.size*25} ${this.size*25},0  ${this.size*25/2},0" fill="${this.color}"/>
                </svg>
            `: html``}
            ${this.value == 5 ? html`
                <div style="background-color: ${this.color}; aspect-ratio: 1;"></div>
            `: html``}         
        `
    }    
}
if (!customElements.get('intrivity-badge-marker')) customElements.define('intrivity-badge-marker', IntrivityBadgeMarker);
export class IntrivityBadgeMarker2 extends LitElement {
    static get properties() {
        return {
            value: { type: Number },
            color: { type: String },
            size: { type: Number }
        };
    }
    constructor() { super(); }
    render() {
        return html`
            ${this.value == -1 ? html`
                <img style="aspect-ratio: 1; width: ${this.size*25}px; color: ${this.color};" src="./images/one.png" />
            `: html``}
            ${this.value == 1 ? html`
                <img style="aspect-ratio: 1; width: ${this.size*25}px; color: ${this.color};" src="./images/one.png" />
            `: html``}
            ${this.value == 2 ? html`
                <img style="aspect-ratio: 1; width: ${this.size*25}px; color: ${this.color};" src="./images/two.png" />
            `: html``}
            ${this.value == 3 ? html`
                <img style="aspect-ratio: 1; width: ${this.size*25}px;; color: ${this.color};" src="./images/three.png" />
            `: html``}
            ${this.value == 4 ? html`
                <img style="aspect-ratio: 1; width: ${this.size*25}px; color: ${this.color};" src="./images/four.png" />
            `: html``}
            ${this.value == 5 ? html`
                <img style="aspect-ratio: 1; width: ${this.size*25}px; color: ${this.color};" src="./images/five.png" />
            `: html``}        
        `
    }    
}
if (!customElements.get('intrivity-badge-marker2')) customElements.define('intrivity-badge-marker2', IntrivityBadgeMarker2);



