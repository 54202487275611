import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import './teams_selector.js';
import './badge.js';

import { getScore } from './utility.js';

export class IntrivityTeams extends LitElement {

    static get styles() {
        return css`
            .teams {
                margin: 25px;
            }
            .group_list {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 10px;
            }
            .group_badge {
                display: grid;
                grid-template-columns: 60px 1fr;
            }
        `;
    }
    
    static get properties() {
        return {
            group_reports: { type: Array },
            group_report: { type: Object },
            size: { type: Number }
        };
    }

    constructor() {
        super(); 
        this.size = 1;
    }

    render() {
        return html`
            <intrivity-teams-selector .group_report=${this.group_report} .group_reports=${this.group_reports}></intrivity-teams-selector>
            <div class="teams">
        
                ${this.group_reports ? html`
                
                    ${this.group_reports.map((rep)=> html`
  
                        <h3>${rep.title}</h3>

                        <div class="group_list">

                            ${rep.users.sort((a, b) => {
                                if (a.lastName + ', ' + a.firstName < b.lastName + ', ' + b.firstName) {
                                  return -1;
                                }
                                if (a.lastName + ', ' + a.firstName > b.lastName + ', ' + b.firstName) {
                                  return 1;
                                }
                                return 0;
    }                       ).map((user=> html`
                                 <div class="group_badge">
                                    <intrivity-badge-plain .m=${getScore(user.profile.m)} .r=${getScore(user.profile.r)} .s=${getScore(user.profile.s)} .t=${getScore(user.profile.t)} .size=${this.size}></intrivity-badge-plain>
                                    <div style="margin-left: 5px;">
                                        <div>${user.lastName}, ${user.firstName}</div>
                                        <div style="font-size: smaller; text-align: center; display: inline-grid; grid-template-columns: 1fr 1fr 1fr 1fr;">
                                            <div>M</div><div>R</div><div>T</div><div>S</div>
                                            <div>${getScore(user.profile.m)}</div>
                                            <div>${getScore(user.profile.r)}</div>
                                            <div>${getScore(user.profile.t)}</div>
                                            <div>${getScore(user.profile.s)}</div>
                                        </div>
                                    <div>
                                </div>
                            ` ))}       

                        </div>  
    
                    `)}

        
                `: html``}

            </div>
        `
    } 
 
}
if (!customElements.get('intrivity-teams')) customElements.define('intrivity-teams', IntrivityTeams);
