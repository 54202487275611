// The improved version of the My Intrivity Application
import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   

import './behaviors_app.js';

export class IntrivityResultsAtGlanceApp extends LitElement {

    static get styles() {
        return css`
            .uppercase-text {
                text-transform: uppercase;
            }
        `;
    }
    
    static get properties() {
        return {
            domain: { type: Object },
            domain_prefix: { type: String },
            report: { type: Object },
            expanded: { type: Boolean },
            printable: { type: Boolean }           // printable view
        };
    }

    constructor() {
        super();
        this.expanded = false;
    }

    connectedCallback() {
        super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }
    
    render() {
        let row_start = 2;
        return html`
            <div style="margin-bottom: 5px; width: 100%; color: black; font-size: 1.5em; padding: 10px; padding-top: 15px; padding-left: 30px; background-color: gray;">
                RESULTS AT A GLANCE
                ${this.printable ? html``: html`
                    ${this.expanded ? html`
                        <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,10 20,10 10,0" /></svg>
                    `: html`
                        <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,0 20,0 10,10" /></svg> 
                    `}
                `}
            </div>
        
            ${this.expanded || this.printable ? html`
                <div style="font-size: 1.2em;   display: flex; flex-wrap: wrap; justify-content: center;">
                    <div style="grid-template-rows: min-content auto; margin: 20px; background-color: ${this.domain.color}; max-width: 300px; display: grid; grid-template-columns: 2fr 1fr;">
                        <div style="background-color: white; padding: 5px; border: 1px solid black; display: flex; align-items: center;">DOMAIN</div>
                        <div style="background-color: white; padding: 5px; border: 1px solid black;">RAW<br />SCORE</div>
                        <div class="uppercase-text" style="padding: 5px; border: 1px solid black; display: flex; align-items: center;">${this.domain.name}</div>
                        <div style=" padding: 5px; border: 1px solid black; display: flex; align-items: center;">${this.report[this.domain_prefix].average}</div>
                    </div>

                    <div>
                        <div style="font-size: smaller; margin: 20px; background-color: ${this.domain.color}; max-width: 600px; display: grid; grid-template-columns: 2fr 1fr 2fr 1fr;">
                            <div style="background-color: white; border: 1px solid black; padding: 5px; display: flex; align-items: center;">CHARACTERISTIC</div>
                            <div style="background-color: white;  border: 1px solid black; padding: 5px;">RAW<br />SCORE</div>
                            <div style="background-color: white;  border: 1px solid black; padding: 5px; display: flex; align-items: center;">Behavior</div>
                            <div style="background-color: white;  border: 1px solid black; padding: 5px;">RAW<br />SCORE</div>

                            ${this.domain.level_two.map((prop, i) => 
                                html`
                                    <div class="uppercase-text" style="display: flex; align-items: center; grid-row-start: ${row_start}; grid-row-end: span ${prop.behaviors.length}; border: 1px solid black; padding: 5px;">${prop.name}</div>
                                    <div style="display: flex; align-items: center; grid-row-start: ${row_start}; grid-row-end: span ${prop.behaviors.length}; border: 1px solid black; padding: 5px;">
                                            ${this.report[this.domain_prefix].level_two[i].average}
                                    </div>
                                    ${prop.behaviors.map((behavior, j) => html`
                                        <div style="background-color: hsl(${this.domain.colorhue} ${this.domain.colorsat}% ${this.domain.colorlight + 15.0}%); font-size: smaller; border: 1px solid black; padding: 5px;">${behavior.name}</div>
                                        <div style="background-color: hsl(${this.domain.colorhue} ${this.domain.colorsat}% ${this.domain.colorlight + 15.0}%); font-size: smaller; border: 1px solid black; padding: 5px;">
                                            ${this.report[this.domain_prefix].level_two[i].behaviors[j].average}
                                        </div>
                                    `)}
                                    ${console.log(row_start = row_start + prop.behaviors.length)}
                                `)}

                        </div>
                        ${ this.report[this.domain_prefix].attitudes && this.report[this.domain_prefix].attitudes.length > 0 ? html`
                        <div style="font-size: smaller; margin: 20px; background-color: ${this.domain.color}; max-width: 600px; display: grid; grid-template-columns: 5fr 1fr;">
                            <div style="font-style: italic; background-color: white; border: 1px solid black; padding: 5px; display: flex; align-items: center;">Attitude</div>
                            <div style="background-color: white;  border: 1px solid black; padding: 5px;">RAW<br />SCORE</div>
                            ${this.domain.attitudes.map((attitude, i) => 
                                html`
                                    <div style="background-color: hsl(${this.domain.colorhue} ${this.domain.colorsat}% ${this.domain.colorlight + 30.0}%); font-size: smaller; font-style: italic; border: 1px solid black; padding: 5px;">${attitude.name}</div>
                                    <div style="background-color: hsl(${this.domain.colorhue} ${this.domain.colorsat}% ${this.domain.colorlight + 30.0}%); font-size: smaller; border: 1px solid black; padding: 5px;">${this.report[this.domain_prefix].attitudes[i].average}</div>                        
                                `)}
                        </div> 
                        `: html``}
                    </div>

                </div>    
            `: html``}
        
        
        `
    } 
 
}
if (!customElements.get('intrivity-results-at-glance')) customElements.define('intrivity-results-at-glance', IntrivityResultsAtGlanceApp);
