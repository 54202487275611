// The improved version of the My Intrivity Application
import { html, css, LitElement } from 'lit-element';
import { clone, cloneDeep } from 'lodash-es';
import { storage } from './intrivity_app.js'
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";


// EVENT CONSTANTS
export const INTRIVITY_GOOGLE_LOGGED_IN = "IntrivityLogin:Google";
export const INTRIVITY_GOOGLE_LOGGED_OUT = "IntrivityLoggedOut:Google";
export const INTRIVITY_LOGIN = "IntrivityLogin:Start";
export const INTRIVITY_LOGGED_IN = "IntrivityLogin:Success";
export const INTRIVITY_LOGOUT = "IntrivityLogin:Stop";
export const INTRIVITY_NAV_SELECT = "IntrivityNav:Select";
export const INTRIVITY_PRINTABLE_TOGGLE = "IntrivityNav:Printable";
export const INTRIVITY_ADMIN = "IntrivityNav:Admin";
export const INTRIVITY_LOAD_GROUPS = "IntrivityGroups:Load";
export const INTRIVITY_LOAD_RESOURCES = "IntrivityResources:Load";
export const INTRIVITY_LOAD_CLIENTS = "IntrivityClients:Load";
export const INTRIVITY_GROUP_SELECT = "IntrivityGroup:Select";
export const INTRIVITY_GROUP_REPORTS_LOADED = "IntrivityGroups:Loaded";
export const INTRIVITY_MASQUERADE = "IntrivityLogin:Masquerade";

export const feedback_mapping = ['a','b','c','d','e','f','g'];

// STATIC FUNCTIONS
// emit an event
export const emitEvent = function (eventName, eventDetails, element) {
    let event = new CustomEvent(
            eventName, {'bubbles': true, 'composed': true, 'detail': eventDetails});
    element.dispatchEvent(event);
    console.log(event);
};
// Determine if the user has a give role
export const hasRole = function(role, user) {
    return user && user.role && user.role.includes(role);
};
// Determine if the "name" is the key of a value in local storage
export const hasLocalStorage = function(name) {
    try {
        let val = localStorage.getItem(name);
        if (val) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        console.log(e);
        return false;
    }
};  
// Based on the row number return the background color CSS of white or gray
export const backgroundRow = function(i) {
        return i % 2 == 0 ? "background-color: #eee;" : "background-color: white;";
};
// Get the net score form a raw score
export const getScore = function(raw) {
        if (raw < 1.9) {
            return 1;
        } else if (raw < 2.7) {
            return 2;
        } else if (raw < 3.5) {
            return 3;
        } else if (raw < 4.3) {
            return 4;
        } else {
            return 5;
        }        
};
// If item equals the net score return the color or disabled color
export const getColor = function(item, raw, color) {
        return item == getScore(raw) ? color: 'lightgrey';
};
// Based on values from 1 to 5, generate an mrts icon as appropriate  
export const getIcon = function(item, raw, color, size) {
        if (item == 1) {
            return html`
                <svg style="aspect-ratio: 1; width: ${size ? size: '25'}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${getColor(1, raw, color)}" fill="transparent" stroke-width="3" />
                </svg>`;
        } else if (item == 2) {
            return html`
                <svg style="aspect-ratio: 1; width: ${size ? size: '25'}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${getColor(2, raw, color)}" fill="transparent" stroke-width="1" />
                    <polygon points="${size ? size/2: 25/2},${size ? size: '25'} ${size ? size: '25'},${size ? size/2: 25/2} ${size ? size: '25'},${size ? size: '25'}" fill="${getColor(2, raw, color)}"/>
                </svg>
            `;
        } else if (item == 3) {
            return html`
                <svg style="aspect-ratio: 1; width: ${size ? size: '25'}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                  <rect width="100%" height="100%" stroke="${getColor(3, raw, color)}" fill="transparent" stroke-width="1" />
                  <polygon points="${size ? size: '25'},0 ${size ? size: '25'},${size ? size: '25'} 0,${size ? size: '25'}" fill="${getColor(3, raw, color)}"/>
                </svg>
            `;
        } else if (item == 4) {
            return html`
                <svg style="aspect-ratio: 1; width: ${size ? size: '25'}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${getColor(4, raw, color)}" fill="transparent" stroke-width="1" />
                    <polygon points="0,${size ? size/2: 12} 0,${size ? size: '25'} ${size ? size: '25'},${size ? size: '25'} ${size ? size: '25'},0 ${size ? size/2: 12},0" fill="${getColor(4, raw, color)}"/>
                </svg>    
            `;
        } else if (item == 5) {
            return html`
                <div style="align: center; background-color: ${getColor(5, raw, color)}; aspect-ratio: 1; width: ${size ? size: '25'}px;"></div>              
            `;
        }
};
export const putJSONInStorage = async function(name, json) {
    var data = JSON.stringify(json);
    const blob = new Blob([data], { type: "application/json" });
    const storageRef = ref(storage, name);
    uploadBytes(storageRef, blob).then((snapshot) => {
        console.log('Put in cloud storage ' + JSON.stringify(json));
    });      
};
export const loadJSONFromStorage = function(elem, var_name, file_name) {
    console.log('Loading from cloud storage: ' + var_name);
    const storageRef = ref(storage, file_name);
    getDownloadURL(storageRef)
        .then(url => fetch(url))
        .then(response => response.json())
        .then(data => { 
            elem[var_name] = data; 
            let store_this = cloneDeep(data);
            store_this.stored = new Date().toISOString();
            localStorage.setItem(var_name, JSON.stringify(store_this)); 
        });
};
export const getJSONFromStorage = function(elem, var_name, file_name, masqueraded_user) {
    try {
        let val = localStorage.getItem(var_name);
        if (val && !masqueraded_user) {
            console.log('From local storage: ' + var_name);

            elem[var_name] = JSON.parse(val);

            // check if last update version is > 1 day old
            if (elem[var_name].stored) {
                let now = new Date();
                let stored = new Date(elem[var_name].stored);
                let dayAgo = new Date(now.setDate(now.getDate() - 1));
                if (dayAgo > stored) { 
                    console.log('Stored more than 1 day ago: ' + var_name);
                    loadJSONFromStorage(elem, var_name, file_name); 
                }
            } else {
                console.log('No stored value for: ' + var_name);
                loadJSONFromStorage(elem, var_name, file_name);
            }

        } else {
            loadJSONFromStorage(elem, var_name, file_name);
        }
    } catch (e) {
        console.log(e);
    }
};
export const loadGroupFromStorage = async function(groups, group, failover) {
    try {
        const storageRef = ref(storage, "reports/groups/" + group + ".json");
        getDownloadURL(storageRef)
            .then(url => fetch(url))
            .then(response => response.json())
            .then(data => { 
                groups.push(data); 
                let store_this = cloneDeep(data);
                store_this.stored = new Date().toISOString();
                localStorage.setItem("reports_" + group, JSON.stringify(store_this));
            });
    } catch (x) {
        console.log(x);
        if (failover) groups.push(failover);
        return group;
    }
};