import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import './badge.js';

export class IntrivityOverview extends LitElement {

    static get styles() {
        return css`
            .nav {
                background-color: rgb(87, 130, 133);
                color: white;
                height: 100%;
                font-size: 24px;
            }
            .selection {
                font-size: 2.0vmax;
                padding-top: 5px; 
                padding-bottom: 5px; 
                padding-left: 40px;
            }
            .selected {
                font-size: 2.0vmax;
                color: rgb(87, 130, 133); 
                background-color: rgb(175, 208, 117);
            }
            .row2 {
                margin: auto; padding-top: 20px; height: 29px;
            }
            .row {
                margin: auto; padding-top: 20px;
            }
        `;
    }
    
    static get properties() {
        return {
            printable: { type: Boolean }           // printable view
        };
    }

    constructor() {
        super(); 
    }

    render() {
        return html`
            <div style="padding: 50px;">
                
                <h2>UNDERSTANDING YOUR RESULTS</h2>
        
                <div style="font-size: smaller; margin-bottom: 20px;">Your personalized "MRTS Profile" defines your Human Interaction Style. It is composed of four numerals and their
                corresponding shapes which represent your style for each respective domain.</div>

                <div style="font-size: smaller;">The assessment evaluates your inclinations and tendencies across each of the four domains of Message, Relationship, Time,
                and Space and results in a raw score for each domain. Your raw score is assigned an MRTS numeral and corresponding
                shape based on the key below. This translates into your personalized four-digit MRTS profile, which serves as a shorthand to
                help you remember your individual tendencies in communicating across the four domains.</div>

                <div style="margin: 30px; display: flex; flex-wrap: wrap; justify-content: center;">
        
                    <div style="color: rgb(87, 130, 133);width: 150px; padding-bottom: 10px; border: 1px solid rgb(87, 130, 133); display: grid; grid-template-columns: 1fr;" >
                            <div style="margin: auto;">RAW<br />SCORE</div>
                            <div class="row2">4.3 - 5.0</div>
                            <div class="row2">3.5 - 4.2</div>
                            <div class="row2">2.7 - 3.4</div>
                            <div class="row2">1.9 - 2.6</div>
                            <div class="row2">1.0 - 1.8</div>
                     </div>
        
                    <div style="margin-top:auto; margin-bottom: auto;">
                        <svg style="width: 45px; height: 25px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                  <polygon points="0,7 0,18 25,18, 25,7" fill="rgb(87, 130, 133)"/>
                                  <polygon points="25,18, 25,25 45,12, 25,0" fill="rgb(87, 130, 133)"/>
                        </svg>
                    </div>        
        
                      <div style="color: rgb(87, 130, 133);width: 150px; padding-bottom: 10px; border: 1px solid rgb(87, 130, 133); display: grid; grid-template-columns: 1fr 1fr;">
                            <div style="margin-left: auto;margin-right: auto;">MRTS</div><div style="margin: auto;">MRTS<br/>SHAPE</div>
                            <div class="row">5</div><div style="padding-top: 20px;margin: auto;">
                                <div style="align: center; background-color: rgb(87, 130, 133); aspect-ratio: 1; width: 25px;"></div>
                            </div>
                            <div class="row">4</div><div style="padding-top: 20px;margin: auto;">
                                <svg style="aspect-ratio: 1; width: 25px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                    <rect width="100%" height="100%" stroke="rgb(87, 130, 133)" fill="transparent" stroke-width="1" />
                                    <polygon points="0,12 0,25 25,25 25,0 12,0" fill="rgb(87, 130, 133)"/>
                                </svg>
                            </div>
                            <div class="row">3</div><div style="padding-top: 20px;margin: auto;">
                                <svg style="aspect-ratio: 1; width: 25px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                  <rect width="100%" height="100%" stroke="rgb(87, 130, 133)" fill="transparent" stroke-width="1" />
                                  <polygon points="25,0 25,25 0,25" fill="rgb(87, 130, 133)"/>
                                </svg>
                            </div>
                            <div class="row">2</div><div style="padding-top: 20px;margin: auto;">
                                <svg style="aspect-ratio: 1; width: 25px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                    <rect width="100%" height="100%" stroke="rgb(87, 130, 133)" fill="transparent" stroke-width="1" />
                                    <polygon points="12,25 25,12 25,25" fill="rgb(87, 130, 133)"/>
                                </svg>
                            </div>
                            <div class="row">1</div><div style="padding-top: 20px;margin: auto;">                
                                <svg style="aspect-ratio: 1; width: 25px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                    <rect width="100%" height="100%" stroke="rgb(87, 130, 133)" fill="transparent" stroke-width="3" />
                                </svg>
                            </div>
                      </div>

                    <div style="margin-top:auto; margin-bottom: auto;">
                        <svg style="width: 45px; height: 25px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                  <polygon points="0,7 0,18 25,18, 25,7" fill="rgb(87, 130, 133)"/>
                                  <polygon points="25,18, 25,25 45,12, 25,0" fill="rgb(87, 130, 133)"/>
                        </svg>
                    </div>  
        
                    <intrivity-badge style="aspect-ratio: 1; cursor: grab; text-align: center; margin-top:auto; margin-bottom: auto; margin-right: 20px;" size="${window.innerWidth < 600 ? '1': '1.5'}" m="-1" r="-2" t="-3" s="-4" type="square"></intrivity-badge>
                
                </div>


                <h3 style="margin-top: 40px;">Examples:</h3>

                <div style="display: grid; grid-template-columns: 1fr 4fr;">

                                        <intrivity-badge style="aspect-ratio: 1; cursor: grab; text-align: center; margin-right: 20px;" size="${window.innerWidth < 600 ? '1': '1.5'}" m="3" r="4" t="5" s="3" type="square"></intrivity-badge>

                                        <div>
                                                This is Jane's personalized profile. Her MRTS is 3453. It indicates her:
                                                 <ul>
                                                    <li>message style is neutral (3)</li>
                                                    <li>relationship style is more relational (4)</li>
                                                    <li>time style is very flexible (5)</li>
                                                    <li>space style is neutral (3)</li>
                                                 </ul>
                                        </div>

                                        <intrivity-badge style="margin-top: 40px; aspect-ratio: 1; cursor: grab; text-align: center; margin-right: 20px;" size="${window.innerWidth < 600 ? '1': '1.5'}" m="3" r="1" t="3" s="2" type="square"></intrivity-badge>

                                        <div style="margin-top: 40px;">
                                                This is John's personalized profile. His MRTS is 3132. It indicates his:
                                                 <ul>
                                                    <li>message style is neutral (3)</li>
                                                    <li>relationship style is very transactional (1)</li>
                                                    <li>time style is neutral (3)</li>
                                                    <li>space style is somewhat reserved (2)</li>
                                                 </ul>
                                        </div>
                </div>
        
            </div>
        `
    } 
 
}
if (!customElements.get('intrivity-overview')) customElements.define('intrivity-overview', IntrivityOverview);
